$transition: all 333ms ease-in-out;

.fade {
  &-enter {
    width: 100%;
    opacity: 0;

    &-active {
      opacity: 1;
      transition: $transition;
      position: absolute;
    }
  }

  &-exit {
    opacity: 1;
    width: 100%;

    &-active {
      opacity: 0.01;
      transition: $transition;
      position: absolute;
    }
  }
}
